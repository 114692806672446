'use client';

import { useTranslations } from 'next-intl';
import Image from 'next/image';
import styles from './Error.module.scss';

export default function ErrorTemplate() {
  const t = useTranslations('ErrorCrash');

  return (
    <div className={styles.container}>
      <Image
        src="/img/error.jpeg"
        alt="Error - 500"
        width={654}
        height={372}
        style={{
          width: 'auto',
          height: 'auto',
        }}
      />

      {/* <img src="/img/error.jpeg" alt="Error - 500" /> */}

      <h2 className="title">{t('title')}</h2>
      <p className="text text--medium">{t('description')}</p>
    </div>
  );
}
